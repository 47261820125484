import { http } from "../utils/http";
import { API_ENDPOINTS } from "../config";
import moment from "moment";
import { getAppointmentsFilter, getSlotFilter } from "../Shared/Utils";
import { store } from "../State/store/configureStore";
import { closeProgress, showProgress } from "../State/actions/uploadProgress";
import I18nProvider from "./I18nProvider";
import Messages from "../Shared/Messages";

class AppointmentsService {
  static getAppointmentStatusMap() {
    const intl = I18nProvider.getIntlProvider();
    return {
      PROPOSED: intl.formatMessage(Messages.proposed),
      SCHEDULED: intl.formatMessage(Messages.scheduled),
      BOOKED: intl.formatMessage(Messages.booked),
      FULFILLED: intl.formatMessage(Messages.fulfilled),
      NOSHOW: intl.formatMessage(Messages.no_show),
      REBOOKED: intl.formatMessage(Messages.rebooked),
    };
  }

  static getSlotStatusMap() {
    const intl = I18nProvider.getIntlProvider();
    return {
      FREE: intl.formatMessage(Messages.fa_slot_status_free),
      BUSY_UNAVAILABLE: intl.formatMessage(Messages.fa_slot_status_busyua),
      BUSY_TENTATIVE: intl.formatMessage(Messages.fa_slot_status_busytt),
      ENTERED_IN_ERROR: intl.formatMessage(Messages.fa_slot_status_error),
    };
  }

  static getAppointmentStatus() {
    const intl = I18nProvider.getIntlProvider();
    return [
      {
        value: "PROPOSED",
        label: intl.formatMessage(Messages.proposed),
      },
      {
        value: "SCHEDULED",
        label: intl.formatMessage(Messages.scheduled),
      },
      {
        value: "BOOKED",
        label: intl.formatMessage(Messages.booked),
      },
      {
        value: "FULFILLED",
        label: intl.formatMessage(Messages.fulfilled),
      },
      {
        value: "NOSHOW",
        label: intl.formatMessage(Messages.no_show),
      },
      {
        value: "REBOOKED",
        label: intl.formatMessage(Messages.rebooked),
      },
    ];
  }

  static getSlotStatus() {
    const intl = I18nProvider.getIntlProvider();
    return [
      {
        label: intl.formatMessage(Messages.fa_slot_status_free),
        value: "FREE",
      },
      {
        label: intl.formatMessage(Messages.fa_slot_status_busyua),
        value: "BUSY_UNAVAILABLE",
      },
      {
        label: intl.formatMessage(Messages.fa_slot_status_busytt),
        value: "BUSY_TENTATIVE",
      },
      {
        label: intl.formatMessage(Messages.fa_slot_status_error),
        value: "ENTERED_IN_ERROR",
      },
    ];
  }

  static getAllSchedules() {
    return http
      .request(
        API_ENDPOINTS.appointmentSchedule + "?page=0&size=1000&allStatus=True"
      )
      .then((data) => {
        if (data && data._embedded) {
          return data._embedded.appointmentSchedules || [];
        }
        return [];
      });
  }

  static createSchedule(data) {
    return http.request(API_ENDPOINTS.appointmentSchedule, "post", data);
  }

  static getASchedule(scheduleId, showAllSlots) {
    let api = API_ENDPOINTS.appointmentSchedule + "/" + scheduleId;
    if (showAllSlots) {
      api = api + "?showAllSlots=true";
    }

    return http.request(api);
  }

  static editSchedule(scheduleId, scheduleData) {
    return http.request(
      API_ENDPOINTS.appointmentSchedule + "/" + scheduleId,
      "patch",
      scheduleData
    );
  }

  static deleteSchedule(scheduleId) {
    return http.request(
      API_ENDPOINTS.appointmentSchedule + "/" + scheduleId,
      "delete"
    );
  }

  static createAppointment(appointmentData) {
    return http
      .request(API_ENDPOINTS.appointments, "post", appointmentData)
      .then((response) => {
        if (!response) {
          return;
        }
        if (Array.isArray(response)) {
          response.forEach((appt) => {
            appt.start = new Date(appt.start);
            appt.end = new Date(appt.end);
          });
        } else {
          if (response.start) {
            response.start = new Date(response.start);
            response.end = new Date(response.end);
          }
        }
        return response;
      });
  }

  static getAllAppointments(
    filter = {},
    scheduleId,
    patientId,
    allStatus,
    start,
    end,
    seriesId
  ) {
    let api =
      API_ENDPOINTS.appointments + "?page=0&size=500&allStatus=" + allStatus;

    api += getAppointmentsFilter(
      filter,
      scheduleId,
      patientId,
      allStatus,
      start,
      end,
      seriesId
    );

    return http.request(api).then((data) => {
      if (data && data._embedded) {
        let appointments = data._embedded.appointments || [];
        appointments.forEach((appointment) => {
          appointment.start = new Date(appointment.start);
          appointment.end = new Date(appointment.end);
        });

        return appointments;
      }
      return [];
    });
  }

  static getAppointment(appointmentId) {
    return http.request(API_ENDPOINTS.appointments + "/" + appointmentId);
  }

  static getAllTemplates(type) {
    let api = API_ENDPOINTS.appointmentTemplates + "?page=0&size=1000";
    if (type) {
      if (type === "group") {
        api += "&group=true";
      } else {
        api += "&group=false";
      }
    }

    return http.request(api).then((data) => {
      if (data && data._embedded) {
        return data._embedded.appointmentTemplates || [];
      }
      return [];
    });
  }

  static createTemplate(data) {
    return http.request(API_ENDPOINTS.appointmentTemplates, "post", data);
  }

  static getATemplate(templateId) {
    return http.request(API_ENDPOINTS.appointmentTemplates + "/" + templateId);
  }

  static editTemplate(templateId, templateData) {
    return http.request(
      API_ENDPOINTS.appointmentTemplates + "/" + templateId,
      "patch",
      templateData
    );
  }

  static deleteTemplate(templateId) {
    return http.request(
      API_ENDPOINTS.appointmentTemplates + "/" + templateId,
      "delete"
    );
  }

  static editAppointment(appointmentId, data, rescheduleSeries) {
    let url = API_ENDPOINTS.appointments + `/${appointmentId}`;
    if (rescheduleSeries) {
      url += "?rescheduleSeries=true"
    }
    return http.request(
      url,
      "patch",
      data
    );
  }

  static cancelAppointment(appointmentId, screeningResult, seriesId, reason) {
    let api = API_ENDPOINTS.appointments + "/" + appointmentId;
    if (screeningResult) {
      api += `?screeningResult=${screeningResult}`;
    }
    if (seriesId) {
      api += `?seriesId=${seriesId}`;
    }
    if (reason) {
      api += `?cancelReason=${reason}`;
    }
    return http.request(api, "delete");
  }

  static createAppointmentFromTemplate(data) {
    return http.request(API_ENDPOINTS.appointmentsFromTemplate, "post", data);
  }

  static getTodaysAppointmentsForStaff(staffId) {
    return http
      .request(
        API_ENDPOINTS.appointments +
          `?staffId=${staffId}&page=0&size=500&todaysAppointmentsOnly=true`
      )
      .then((data) => {
        let appointments = [];
        if (data && data._embedded) {
          appointments = data._embedded.appointments || [];
        }

        appointments = appointments.filter((appointment) =>
          moment().isBefore(moment(appointment.end))
        );
        return appointments.sort((a, b) => a.start - b.start);
      });
  }

  static getPublishedSchedules() {
    return http
      .request(API_ENDPOINTS.publishedSchedules + `?page=0&size=100`)
      .then((data) => {
        let schedules = [];
        if (data && data._embedded) {
          schedules = data._embedded.appointmentPublishedSchedules || [];
        }
        return schedules;
      });
  }

  static shareScheduleLink = (patientId, link) => {
    return http.request(API_ENDPOINTS.sendMessageToPatients(), "post", {
      patientIds: [patientId],
      campaignMode: "SELECTED_PATIENTS",
      smsMessage: "Book your appointment here " + link,
      emailSubject: "Book your appointment here",
      emailMessage:
        '<div>Hello _PatientName, you can <a href="' +
        link +
        '" style="color: rgb(0, 139, 128);">book your appointment</a>. </div><div style="margin-top: 20px;">From </div><div>_FacilityName</div>',
    });
  };

  static loadTemplateParametersForComments() {
    return http
      .request(API_ENDPOINTS.loadTemplateParameters("ALL"))
      .then((data) => {
        return data && data._embedded ? data._embedded.valueParameters : [];
      });
  }

  static getSlots(filter = {}, scheduleId, start, end, countOnly, allStatus) {
    const intl = I18nProvider.getIntlProvider();
    let api = API_ENDPOINTS.slots + "?";
    api += getSlotFilter(filter, scheduleId, start, end, countOnly, allStatus);

    return http.request(api).then((slots = []) => {
      if (countOnly) {
        slots.forEach((slot) => {
          slot.allDay = true;
          slot.start = new Date(slot.statDate);
          slot.end = new Date(slot.statDate);
          slot.title =
            slot.count === 1
              ? intl.formatMessage(Messages.one_slot)
              : intl.formatMessage(Messages.count_slots, { count: slot.count });
        });
      } else {
        slots.forEach((slot) => {
          slot.start = new Date(slot.start);
          slot.end = new Date(slot.end);
        });
      }
      return slots;
    });
  }

  static createSlots(scheduleId, slotsData) {
    return http.request(
      API_ENDPOINTS.scheduleSlots(scheduleId),
      "post",
      slotsData
    );
  }

  static sendStartNotification(appointmentId, data) {
    return http.request(
      API_ENDPOINTS.appointmentStartNotification(appointmentId),
      "post",
      data
    );
  }

  static editSlot(slotId, slotData) {
    return http.request(API_ENDPOINTS.slots + "/" + slotId, "patch", slotData);
  }

  static deleteSlots(data) {
    let api = API_ENDPOINTS.slots;
    if (data.slotId) {
      api = api + "?slotId=" + data.slotId;
    } else {
      api =
        api +
        "?scheduleId=" +
        data.scheduleId +
        "&start=" +
        moment(new Date(data.start)).format("DDMMYYYY") +
        "&end=" +
        moment(new Date(data.end)).format("DDMMYYYY");
      if (data.participant) {
        api = api + "&staffId=" + data.participant.staffId;
      }
    }

    return http.request(api, "delete");
  }

  static fetchAppointmentAnonymous(appointmentId, pId, uId) {
    return http.request(
      API_ENDPOINTS.anonAppointment(appointmentId) + `?pid=${pId}&uid=${uId}`
    );
  }

  static cancelAppointmentAnonymous(appointmentId, pId, uId) {
    return http.request(
      API_ENDPOINTS.anonAppointment(appointmentId) + `?pid=${pId}&uid=${uId}`,
      "delete"
    );
  }

  static loadAuditLogs(appointmentId) {
    return http.request(API_ENDPOINTS.appointmentAudits(appointmentId));
  }

  static fetchPatientAppointments(patientId, page) {
    return http
      .request(API_ENDPOINTS.patientAppointments(patientId, page, 20))
      .then((response) => {
        if (response && response._embedded) {
          return {
            appointments: response._embedded.appointments || [],
            pageInfo: response.page,
          };
        }
        return {
          appointments: [],
          pageInfo: { number: -1 },
        };
      });
  }

  static fetchClinics = (page, filter) => {
    let {
      specialityCode,
      scheduleId,
      assigneeId,
      assigneeReferenceType,
      clinicDateFrom,
      clinicDateTo,
    } = filter;

    let api = API_ENDPOINTS.clinics() + `?page=${page}&size=50`;
    if (specialityCode) {
      api += `&specialityCode=${specialityCode}`;
    }
    if (scheduleId) {
      api += `&scheduleId=${scheduleId}`;
    }
    if (assigneeId) {
      if (assigneeReferenceType === "CARE_TEAM") {
        api += `&taskAssigneeCareteamId=${assigneeId}`;
      } else {
        api += `&taskAssigneeStaffId=${assigneeId}`;
      }
    }
    if (clinicDateFrom) {
      api += `&clinicDateFrom=${moment(clinicDateFrom).format("DDMMYYYY")}`;
    }
    if (clinicDateTo) {
      api += `&clinicDateTo=${moment(clinicDateTo).format("DDMMYYYY")}`;
    }

    return http.request(api).then((response) => {
      if (response && response._embedded) {
        return {
          data: response._embedded.clinics || [],
          loadMore: response.page.totalPages > page + 1,
          total: response.page.totalElements,
        };
      } else {
        return {
          data: [],
          loadMore: false,
        };
      }

      // if (response && response._embedded) {
      //   return {
      //     clinics: response._embedded.clinics || [],
      //     pageInfo: response.page
      //   };
      // }
      // return {
      //   clinics: [],
      //   pageInfo: { number: -1 }
      // };
    });
  };

  static fetchClinic = (clinicId, scheduleId, clinicDate) => {
    return http.request(
      API_ENDPOINTS.clinics(clinicId) +
        `?scheduleId=${scheduleId}&clinicDate=${clinicDate}`
    );
  };

  static fetchClinicAppointments = (
    page,
    scheduleId,
    clinicDate,
    clinicStat,
    clinicStatValue,
    sortBy,
    sortDir
  ) => {
    let api =
      API_ENDPOINTS.appointments +
      `?page=${page}&size=50&scheduleId=` +
      scheduleId +
      "&clinicDate=" +
      clinicDate;

    if (clinicStat && clinicStatValue) {
      api += `&${clinicStat}=${clinicStatValue}`;
    }
    if (sortBy) {
      api += `&sortBy=${sortBy}`;
    }
    if (sortDir) {
      api += `&sortDir=${sortDir}`;
    }

    return http.request(api).then((response) => {
      if (response && response._embedded) {
        return {
          appointments: response._embedded.appointments || [],
          pageInfo: response.page,
        };
      }
      return {
        patients: [],
        pageInfo: { number: -1 },
      };

      // if (data && data._embedded) {
      //   let appointments = data._embedded.appointments || [];
      //   appointments.forEach(appointment => {
      //     appointment.start = new Date(appointment.start);
      //     appointment.end = new Date(appointment.end);
      //   });
      //
      //   return appointments;
      // }
      // return [];
    });
  };

  static importClinicAppointments = (csvFile) => {
    const formData = new FormData();
    formData.append("files", csvFile);

    return http
      .request(
        API_ENDPOINTS.appointmentsImportCSV() + "?ignoreDuplicates=true",
        "post",
        formData,
        null,
        (progressEvent) => {
          const uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.dispatch(showProgress(uploadProgress));
        },
        120000
      )
      .then((response) => {
        store.dispatch(closeProgress());
        let strings = [];
        if (response._embedded && response._embedded.strings) {
          strings = response._embedded.strings || [];
        }
        return strings;
      })
      .catch((e) => {
        store.dispatch(closeProgress());
      });
  };

  static fetchClinicReport(clinicId, scheduleId, clinicDate, send) {
    let api =
      API_ENDPOINTS.clinics(clinicId) +
      `?scheduleId=${scheduleId}&clinicDate=${clinicDate}`;
    if (send) {
      api += "&sendDetailedReport=true";
    } else {
      api += "&withDetailedReport=true";
    }
    return http.request(api);
  }

  static revertCancel(appointmentId) {
    return http.request(
      API_ENDPOINTS.revertAppointmentCancel(appointmentId),
      "POST",
      {}
    );
  }

  static importSchedules = (csvFile) => {
    const formData = new FormData();
    formData.append("files", csvFile);

    return http
      .request(
        API_ENDPOINTS.csvSchedules(),
        "post",
        formData,
        null,
        (progressEvent) => {
          const uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.dispatch(showProgress(uploadProgress));
        },
        120000
      )
      .then((response) => {
        store.dispatch(closeProgress());
        let strings = [];
        if (response._embedded && response._embedded.strings) {
          strings = response._embedded.strings || [];
        }
        return strings;
      })
      .catch((e) => {
        store.dispatch(closeProgress());
      });
  };

  static fetchClinicReports = (filter, sort, output) => {
    let api =
      API_ENDPOINTS.clinicReports() +
      `?` +
      AppointmentsService.getClinicsReportCriteria(filter, sort);

    if (output) {
      api += `&output=${output}`;
    }

    return http.request(api);
  };

  static fetchClinicReportsCSV = (filter, sort) => {
    let api =
      API_ENDPOINTS.clinicReportsCSV() +
      "?" +
      AppointmentsService.getClinicsReportCriteria(filter, sort);
    return http.request(api);
  };

  static getClinicsReportCriteria(filter, sort) {
    let {
      specialityCode,
      scheduleId,
      assigneeId,
      assigneeReferenceType,
      clinicDateFrom,
      clinicDateTo,
      callDateFrom,
      callDateTo,
    } = filter;

    let api = "";

    if (specialityCode) {
      api += `&specialityCode=${specialityCode}`;
    }
    if (scheduleId) {
      api += `&scheduleId=${scheduleId}`;
    }
    if (assigneeId) {
      if (assigneeReferenceType === "CARE_TEAM") {
        api += `&taskAssigneeCareteamId=${assigneeId}`;
      } else {
        api += `&taskAssigneeStaffId=${assigneeId}`;
      }
    }
    if (clinicDateFrom) {
      api += `&clinicDateFrom=${moment(clinicDateFrom).format("DDMMYYYY")}`;
    }
    if (clinicDateTo) {
      api += `&clinicDateTo=${moment(clinicDateTo).format("DDMMYYYY")}`;
    }

    if (callDateFrom) {
      api += `&callDateFrom=${moment(callDateFrom).format("DDMMYYYY")}`;
    }
    if (callDateTo) {
      api += `&callDateTo=${moment(callDateTo).format("DDMMYYYY")}`;
    }

    if (sort) {
      if (sort.column) {
        api += `&sortColumn=${sort.column}`;
      }
      if (sort.order) {
        api += `&sortOrder=${sort.order}`;
      }
    }
    return api;
  }

  static fetchClinicsUploadReports = (page, size) => {
    return http
      .request(API_ENDPOINTS.clinicUploadReport + `?page=${page}&size=${size}`)
      .then((response) => {
        if (response && response._embedded) {
          return {
            reports: response._embedded.cSVDataLoadAudits || [],
            pageInfo: response.page,
          };
        }
        return {
          reports: [],
          pageInfo: { number: -1 },
        };
      });
  };

  static cloneTemplate(templateId, data) {
    return http.request(
      API_ENDPOINTS.apptTemplateClone(templateId),
      "post",
      data
    );
  }
}

const appointmentsService = AppointmentsService;
export default appointmentsService;
