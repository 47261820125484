import { combineReducers } from "redux";
import userReducer from "./userReducer";
import organizationsReducer from "./organizationsReducer";
import patientsReducer from "./patientsReducer";
import patientTypesReducer from "./patientTypesReducer";
import statusesReducer from "./statusesReducer";
import surveysReducer from "./surveysReducer";
import staffReducer from "./staffReducer";
import organizationAdminsReducer from "./organizationAdminsReducer";
import contentLibraryReducer from "./contentLibraryReducer";
import carePlanReducer from "./carePlanReducer";
import infoPanelReducer from "./infoPanelReducer";
import consentDocumentReducer from "./consentDocumentReducer";
import toasterReducer from "./toasterReducer";
import uploadProgressReducer from "./uploadProgressReducer";
import mpsPackageReducer from "./mpsPackageReducer";
import masterDataReducer from "./masterDataReducer";
import membersReducer from "./membersReducer";
import clinicsReducer from "./ClinicsReducer";
import globalReducer from "./globalReducer";

// Root Reducer
const rootReducer = combineReducers({
  user: userReducer,
  organizations: organizationsReducer,
  staff: staffReducer,
  organizationAdmins: organizationAdminsReducer,
  patients: patientsReducer,
  members: membersReducer,
  patientTypes: patientTypesReducer,
  statuses: statusesReducer,
  surveys: surveysReducer,
  contentLibrary: contentLibraryReducer,
  carePlan: carePlanReducer,
  infoPanel: infoPanelReducer,
  consentDocument: consentDocumentReducer,
  toaster: toasterReducer,
  uploadProgress: uploadProgressReducer,
  mpsPackage: mpsPackageReducer,
  masterData: masterDataReducer,
  clinics: clinicsReducer,
  global: globalReducer
});

export default rootReducer;
