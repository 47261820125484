import {
  SET_GLOBAL_STORAGE
} from "../actions/actionTypes";

let initialState = {
  attributeUIFields: []
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_STORAGE:
      return {
        ...state,
        attributeUIFields: action.data
       };

    default:
      return state;
  }
};

export default globalReducer;
