import React, { Component } from "react";
import { Input } from "reactstrap";
import CountryCodes from "../../../FHIR/CountryCodes";
import { formatPhoneNumber } from "../../../../utils/phone";
import classnames from "classnames";
import { getSystemField } from "../../../Utils";
import I18nProvider from "../../../../services/I18nProvider";
import Messages from "../../../Messages";
import MPSSelect from "../../../../components/MPSSelect";
import { connect } from "react-redux";
const intl = I18nProvider.getIntlProvider();

class ContactPoint extends Component {
  componentDidMount() {
    const inputs = document.getElementsByClassName("cc-confirm-field");
    if (inputs && inputs.length >= 1) {
      for (let i = 0; i < inputs.length; i++) {
        let input = inputs[i];
        input.onpaste = function(e) {
          e.preventDefault();
        };
      }
    }
  }

  static getDisplayValue(av) {
    let value = av.valueContactPoints
      ? av.valueContactPoints
        ? av.valueContactPoints[0].value
        : null
      : null;

    let system = av.valueContactPoints
      ? av.valueContactPoints
        ? av.valueContactPoints[0].system
        : null
      : null;

    if (system === "PHONE" || system === "TEL") {
      let countryCode = av.valueContactPoints
        ? av.valueContactPoints
          ? av.valueContactPoints[0].countryCode
          : null
        : null;
      value = formatPhoneNumber(countryCode, value);
    }
    return value;
  }

  static getDisplayValueInFilter(av) {
    return av.valueContactPoints
      ? av.valueContactPoints
        ? av.valueContactPoints[0].value
        : null
      : null;
  }

  render() {
    let {
      dataField,
      attributeValue,
      setData,
      placeholder,
      hasError,
      id,
      borderRadius,
      countryCodes
    } = this.props;

    attributeValue.valueContactPoints = attributeValue.valueContactPoints || [];
    attributeValue.valueContactPoints[0] = attributeValue
      .valueContactPoints[0] || {
      system: getSystemField(dataField),
      use: "WORK"
    };
    let value = attributeValue.valueContactPoints[0].value;
    if (
      dataField === "PatientContactPhone" ||
      dataField === "PatientContactTel" ||
      dataField === "GPPhone"
    ) {
      let countryCode = attributeValue.valueContactPoints[0].countryCode;
      let errorClass = this.props.onboarding ? "is-obform-invalid" : "";

      return (
        <div
          className={
            this.props.width === 100 ? "inputHolder width-100" : "inputHolder"
          }
          style={{
            marginBottom: 0
          }}
        >
          <div className="d-flex flex-row">
            <MPSSelect
              value={countryCode}
              onChange={cc => {
                if (cc) {
                  attributeValue.valueContactPoints[0].countryCode = cc.value;
                } else {
                  attributeValue.valueContactPoints[0].countryCode = null;
                }
                setData(attributeValue, cc ? cc.value + " " + value : value);
              }}
              placeholder={intl.formatMessage(Messages.country)}
              options={countryCodes}
              labelKey="country"
              valueKey="value"
              className="mr-1 ui-attribute-el-select"
              clearable={this.props.ccClearable}
              onBlur={this.props.onBlur}
              style={{ border: "1px solid #c0c0c0" }}
            />
            <Input
              placeholder={placeholder || intl.formatMessage(Messages.phone_cc)}
              className={classnames(
                hasError
                  ? `${errorClass} is-invalid form-control ui-attribute-el`
                  : "form-control ui-attribute-el"
              )}
              style={{
                width: "50%"
              }}
              value={value}
              onChange={e => {
                attributeValue.valueContactPoints[0].value = e.target.value;
                setData(attributeValue, countryCode + " " + e.target.value);
              }}
              type="search"
              autoComplete="nope"
              onBlur={this.props.onBlur}
              id={id}
            />
          </div>

          {this.props.screenType === "ADD_PATIENT" && (
            <div className="d-flex flex-row mt-1">
              <MPSSelect
                value={attributeValue.valueContactPoints[0].cfCountryCode}
                onChange={cc => {
                  if (cc) {
                    attributeValue.valueContactPoints[0].cfCountryCode =
                      cc.value;
                  } else {
                    attributeValue.valueContactPoints[0].cfCountryCode = null;
                  }
                  setData(attributeValue, cc ? cc.value + " " + value : value);
                }}
                placeholder={intl.formatMessage(Messages.country)}
                options={countryCodes}
                labelKey="country"
                valueKey="value"
                className="mr-1 ui-attribute-el-select"
                clearable={this.props.ccClearable}
                onBlur={this.props.onBlur}
                style={{ border: "1px solid #c0c0c0" }}
              />
              <Input
                placeholder={
                  placeholder || intl.formatMessage(Messages.confirm_phone)
                }
                className={classnames(
                  hasError
                    ? `${errorClass} is-invalid form-control ui-attribute-el cc-confirm-field`
                    : "form-control ui-attribute-el cc-confirm-field"
                )}
                style={{
                  width: "50%"
                }}
                value={attributeValue.valueContactPoints[0].cfPhone}
                onChange={e => {
                  attributeValue.valueContactPoints[0].cfPhone = e.target.value;
                  setData(attributeValue, countryCode + " " + e.target.value);
                }}
                type="search"
                autoComplete="nope"
                onBlur={this.props.onBlur}
                id={id}
              />
            </div>
          )}
        </div>
      );
    } else if (dataField === "PatientContactEmail" || dataField === "GPEmail") {
      return (
        <div
          className={this.props.width === 100 ? "width-100" : ""}
          style={{
            marginBottom: 0
          }}
        >
          <div className="inputHolder">
            <Input
              className="ui-attribute-el"
              placeholder={placeholder || intl.formatMessage(Messages.email)}
              value={value}
              onChange={e => {
                attributeValue.valueContactPoints[0].value = e.target.value;
                setData(attributeValue, e.target.value);
              }}
              type="text"
              onBlur={this.props.onBlur}
            />

            {this.props.screenType === "ADD_PATIENT" && (
              <Input
                className="ui-attribute-el mt-1 cc-confirm-field"
                placeholder={intl.formatMessage(Messages.confirm_email)}
                value={attributeValue.valueContactPoints[0].cfEmail}
                onChange={e => {
                  attributeValue.valueContactPoints[0].cfEmail = e.target.value;
                  setData(attributeValue, e.target.value);
                }}
                type="text"
                onBlur={this.props.onBlur}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let defaultTelRegionOptions = ownProps.defaultTelRegionOptions
    ? ownProps.defaultTelRegionOptions
    : state.user.data
    ? state.user.data.defaultTelRegionOptions
    : null;

  let countryCodes = CountryCodes;
  if (defaultTelRegionOptions && defaultTelRegionOptions.length >= 1) {
    countryCodes = CountryCodes.filter(
      c => defaultTelRegionOptions.indexOf(c.code) !== -1
    );
  }

  return {
    countryCodes
  };
};

export default connect(mapStateToProps)(ContactPoint);
